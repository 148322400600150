<template>
  <v-dialog v-model="api_dialog" max-width="500">
    <v-card>
      <v-card-title>
        <h3 class="title-transaction">
          {{ $t("banks.select") }}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="api_selected"
          :loading="api_banks_loading"
          :items="api_banks"
          item-text="name"
          item-value="id"
        >
        </v-autocomplete>
        <v-alert
          type="info"
          v-if="bank_selected && bank_selected.nordigen_api_expires"
        >
          {{
            $t("banks.api_expires") + " " + bank_selected.nordigen_api_expires
          }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="cyan"
          v-if="bank_selected && bank_selected.nordigen_api_expires"
          @click="syncTransfers()"
          >{{ $t("banks.sync") }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="api_dialog = false">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="green" @click="setupSync()" :disabled="!api_selected">
          {{ $t("banks.authorize") }}
        </v-btn>
      </v-card-actions>

      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        savedMessage
      }}</v-alert>
      <v-alert v-if="errorMessage" tile type="error" class="mb-0"
        >{{ errorMessage }}
        <div
          v-if="errorLog.info"
          style="max-height: 50vh; overflow-y: auto; margin-top: 5px"
        >
          <p v-for="(v, i) in errorLog.info" :key="'tr' + i">
            {{ i }}: {{ v }}
          </p>
        </div>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    meta: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      savedMessage: null,
      errorMessage: null,
      errorLog: {},
      savedItem: false,
      api_banks_loading: true,
      api_banks: [],
      api_bank: null,
      api_selected: null,
      api_dialog: false,
      api_requisition_id: null,
      bank_selected: null,
    };
  },
  mounted() {},
  methods: {
    getBanksList(item) {
      this.savedItem = false;
      this.savedMessage = false;
      this.errorMessage = null;
      this.errorLog = {};
      this.api_bank = item.id;
      this.bank_selected = item;
      this.api_dialog = true;
      this.api_selected = null;
      if (!this.api_banks.length) {
        this.get("/bank_api/list").then((data) => {
          this.api_banks = data;
          this.api_banks_loading = false;
          this.api_selected = item.nordigen_bank_id;
        });
      } else {
        this.api_selected = item.nordigen_bank_id;
      }
    },
    setupSync() {
      this.api_dialog = false;
      this.get(
        "/bank_api/authorize_link/" + this.api_bank + "/" + this.api_selected
      ).then((data) => {
        this.api_requisition_id = data.requisition_id;
        window.open(
          data.link,
          "bank_authorisation",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
        );
      });
    },
    syncTransfers() {
      if (!this.bank_selected) {
        return;
      }
      this.post("/banks/import/" + this.bank_selected.id, new FormData())
        .then((r) => {
          this.loading = false;
          this.sending = false;
          if (r.success) {
            this.savedItem = true;
            this.savedMessage = r.message;
          } else {
            this.errorMessage = r.message;
            this.errorLog = r.log;
          }
          this.$emit("imported");
        })
        .catch((r) => {
          this.errorMessage = r.message;
        });
    },
  },
};
</script>
