<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="70vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-title>
          <h3 class="title-transaction">{{ formTitle }}</h3>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.account_name"
                :label="$t('banks.table.account-name')"
                :rules="rules.account_name"
                prepend-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="p-0">
                <v-col cols="12" md="6">
                  <v-switch
                    class="pt-0"
                    v-model="form.active"
                    :label="$t('banks.table.active')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    class="pt-0"
                    v-model="form.payout"
                    :label="$t('banks.table.payout')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    class="pt-0"
                    v-model="form.default_sepa"
                    :label="$t('banks.default_sepa')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    class="pt-0"
                    v-model="form.default_dd"
                    :label="$t('banks.default_dd')"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.account_holder"
                :label="$t('banks.table.account-holder')"
                :rules="rules.account_holder"
                prepend-icon="mdi-account-details"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.account_number"
                :label="$t('pay.Account number')"
                :rules="rules.account_number"
                prepend-icon="mdi-account-details"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.iban"
                :label="$t('banks.table.iban')"
                :rules="rules.iban"
                prepend-icon="mdi-credit-card"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.bic"
                :label="$t('banks.table.bic')"
                :rules="rules.bic"
                prepend-icon="mdi-credit-card"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.type"
                :items="meta"
                prepend-icon="mdi-bank"
                menu-props="auto"
                hide-details
                :label="$t('banks.table.type')"
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.bank_name"
                :label="$t('banks.table.bank_name')"
                :rules="rules.bank_name"
                prepend-icon="mdi-bank"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="gray" :disabled="sending" @click="closeForm">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("banks.saved")
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    meta: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiQuery: "/banks/",
      loading: false,
      rules: {
        type: [
          (v) => !!v || "Required.",
          () =>
            this.serverErrors && this.serverErrors.type
              ? this.$t("banks.errors." + this.serverErrors.type[0])
              : true,
        ],
        account_name: [
          (v) => !!v || "Required.",
          () =>
            this.serverErrors && this.serverErrors.account_name
              ? this.$t("banks.errors." + this.serverErrors.account_name[0])
              : true,
        ],
        account_number: [
          () =>
            this.serverErrors && this.serverErrors.account_number
              ? this.$t("banks.errors." + this.serverErrors.account_number[0])
              : true,
        ],
        account_holder: [
          () =>
            this.serverErrors && this.serverErrors.account_holder
              ? this.$t("banks.errors." + this.serverErrors.account_holder[0])
              : true,
        ],
        bank_name: [
          () =>
            this.serverErrors && this.serverErrors.bank_name
              ? this.$t("banks.errors." + this.serverErrors.bank_name[0])
              : true,
        ],
        bic: [
          (v) => !!v || "Required.",
          (v) => {
            return !v || validate.bic.test(v) ? true : "Invalid bic";
          },
          () =>
            this.serverErrors && this.serverErrors.bic
              ? this.$t("banks.errors." + this.serverErrors.bic[0])
              : true,
        ],
        iban: [
          (v) => !!v || "Required.",
          (v) => {
            return !v || validate.iban.test(v) ? true : "Invalid iban";
          },
          () =>
            this.serverErrors && this.serverErrors.iban
              ? this.$t("banks.errors." + this.serverErrors.iban[0])
              : true,
        ],
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("Add bank")
        : this.$t("Edit bank");
    },
  },
  mounted() {},
  methods: {},
};
</script>
