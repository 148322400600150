var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"bank-transfers-table","fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-bank","title":_vm.$t('banks.page title')}},[_c('bank-form',{ref:"editForm",attrs:{"meta":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('import-transfers-form',{ref:"importTransfersForm",attrs:{"meta":_vm.meta},on:{"imported":_vm.reload}}),_c('nordigen-bank-form',{ref:"nordigen",on:{"imported":_vm.reload}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","footer-props":{
        'items-per-page-options': [10, 20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-check" : "mdi-close"))])]}},{key:"item.payout",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.payout ? "mdi-check" : "mdi-close"))])]}},{key:"item.default_sepa",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.default_sepa ? "mdi-check" : "mdi-none"))])]}},{key:"item.default_dd",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.default_dd ? "mdi-check" : "mdi-none"))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('banks.edit')},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),(item.has_token === false)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('banks.activate')},on:{"click":function($event){return _vm.onActivate(item)}}},[_vm._v("mdi-account-key")]):[_c('v-icon',{staticClass:"mr-2",attrs:{"title":_vm.$t('banks.import')},on:{"click":function($event){return _vm.onImportTransactions(item)}}},[_vm._v("mdi-database-import")]),_c('v-icon',{staticClass:"mr-2",attrs:{"title":_vm.$t('banks.api sync')},on:{"click":function($event){return _vm.onNordigenSetup(item)}}},[_vm._v("mdi-database-sync")])]]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-6",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bank-plus")]),_vm._v(" "+_vm._s(_vm.$t("Add bank"))+" ")],1)],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }