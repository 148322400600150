var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"bank-transfers-table","fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-export","title":_vm.$t('dd.page title')}},[_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('dd.delete')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(" Are you sure you want to delete the file "),_c('strong',[_vm._v(_vm._s(item.filename))]),_vm._v("? ")]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_c('strong',[_vm._v(_vm._s(item.filename))]),_vm._v(" deleted. ")]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":"created_at","sort-desc":"","footer-props":{}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-3",attrs:{"tag":"a","download":"","title":_vm.$t('dd.download')},on:{"click":function($event){return _vm.onDownloaded(item)}}},[_vm._v("mdi-download")]),_c('v-icon',{attrs:{"title":_vm.$t('dd.delete')},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")])]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"title":_vm.$t('dd.refresh')},on:{"click":function($event){return _vm.generateRefresh()}}},[_vm._v("mdi-table-refresh")])]}},{key:"item.downloaded",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.downloaded ? "mdi-check" : "mdi-none"))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("lll"))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }