<template>
  <v-container id="bank-transfers-table" fluid tag="section">
    <base-material-card icon="mdi-bank" :title="$t('banks.page title')">
      <bank-form ref="editForm" @itemSaved="onSaved" :meta="meta"></bank-form>
      <import-transfers-form
        ref="importTransfersForm"
        :meta="meta"
        @imported="reload"
      ></import-transfers-form>
      <nordigen-bank-form
        ref="nordigen"
        @imported="reload"
      ></nordigen-bank-form>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.active="{ item }">
          <v-icon>{{ item.active ? "mdi-check" : "mdi-close" }}</v-icon>
        </template>
        <template v-slot:item.payout="{ item }">
          <v-icon>{{ item.payout ? "mdi-check" : "mdi-close" }}</v-icon>
        </template>
        <template v-slot:item.default_sepa="{ item }">
          <v-icon>{{ item.default_sepa ? "mdi-check" : "mdi-none" }}</v-icon>
        </template>
        <template v-slot:item.default_dd="{ item }">
          <v-icon>{{ item.default_dd ? "mdi-check" : "mdi-none" }}</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="onEdit(item)"
            :title="$t('banks.edit')"
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="item.has_token === false"
            small
            class="mr-2"
            @click="onActivate(item)"
            :title="$t('banks.activate')"
            >mdi-account-key</v-icon
          >
          <template v-else>
            <v-icon
              class="mr-2"
              @click="onImportTransactions(item)"
              :title="$t('banks.import')"
              >mdi-database-import</v-icon
            >
            <v-icon
              class="mr-2"
              @click="onNordigenSetup(item)"
              :title="$t('banks.api sync')"
              >mdi-database-sync</v-icon
            >
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-btn color="primary" dark class="mb-6" @click="onAdd">
              <v-icon left>mdi-bank-plus</v-icon>
              {{ $t("Add bank") }}
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import crudTable from "mixins/crudTable";
import bankForm from "../form/BankForm";
import importTransfersForm from "../form/ImportTransfersForm";
import nordigenBankForm from "../form/NordigenBankForm";

export default {
  components: {
    bankForm,
    importTransfersForm,
    nordigenBankForm,
  },
  mixins: [crudTable],
  created() {
    this.get("/banks/create").then((data) => {
      this.meta = data;
    });
  },
  mounted() {
    window.addEventListener("message", this.bankActivated, false);
  },
  data() {
    return {
      meta: [],
      apiQuery: "/banks",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("banks.table.type"), value: "type" },
        { text: this.$t("banks.table.account-name"), value: "account_name" },
        { text: this.$t("banks.table.iban"), value: "iban" },
        { text: this.$t("banks.table.active"), value: "active" },
        { text: this.$t("banks.table.payout"), value: "payout" },
        { text: this.$t("banks.table.sepa"), value: "default_sepa" },
        { text: this.$t("banks.table.dd"), value: "default_dd" },
        { text: this.$t("banks.table.last_import"), value: "last_import" },

        { text: this.$t("banks.table.actions"), value: "actions" },
      ],
    };
  },
  methods: {
    bankActivated(event) {
      if (event.origin !== document.location.origin) return;
      if (event.data.id === "bank_activation" && event.data.error == false) {
        const index = this.items
          .map((item) => item.id)
          .indexOf(parseInt(event.data.bank));
        if (index > -1) {
          this.items[index].has_token = true;
        }
      }
    },
    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item));
    },
    onActivate(item) {
      window.open(
        item.activate_bank,
        "activation",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
      );
    },
    onSaved(item, index) {
      this.reload(index);
      window.setTimeout(() => {
        this.$refs.editForm.closeForm();
      }, 1500);
    },
    onImportTransactions(item) {
      this.$refs.importTransfersForm.editItem(item, this.items.indexOf(item));
    },
    onNordigenSetup(item) {
      this.$refs.nordigen.getBanksList(item, this.items.indexOf(item));
    },
  },
};
</script>
