<template>
  <v-container id="bank-transfers-table" fluid tag="section">
    <base-material-card icon="mdi-export" :title="$t('dd.page title')">
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('dd.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          Are you sure you want to delete the file
          <strong>{{ item.filename }}</strong
          >?
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">
            <strong>{{ item.filename }}</strong> deleted.
          </v-alert>
        </template>
      </delete-dialog>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        sort-by="created_at"
        sort-desc
        :footer-props="{}"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            tag="a"
            class="pr-3"
            download
            :title="$t('dd.download')"
            @click="onDownloaded(item)"
            >mdi-download</v-icon
          >
          <v-icon :title="$t('dd.delete')" @click="onDelete(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template v-slot:header.actions="{ header }">
          {{ header.text }}
          <v-icon :title="$t('dd.refresh')" @click="generateRefresh()"
            >mdi-table-refresh</v-icon
          >
        </template>
        <template v-slot:item.downloaded="{ item }">
          <v-icon>{{ item.downloaded ? "mdi-check" : "mdi-none" }}</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ moment(item.created_at).format("lll") }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudTable from "mixins/crudTable";
import deleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    deleteDialog,
  },
  mixins: [crudTable],
  data() {
    return {
      meta: [],
      apiQuery: "/sepa/files/",
      headers: [
        { text: this.$t("dd.date"), value: "created_at" },
        { text: this.$t("dd.file"), value: "filename" },
        { text: this.$t("dd.downloaded"), value: "downloaded" },
        { text: this.$t("dd.actions"), value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    moment,
    onDownloaded(item) {
      this.download(this.apiQuery + item.id, item.filename)
        .then(() => {
          item.downloaded = 1;
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    generateRefresh() {
      this.get("/sepa/refresh/")
        .then(() => {
          this.reload();
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
};
</script>
