<template>
  <div id="main-content-bank-transactions">
    <banks-table></banks-table>
    <direct-debit-table></direct-debit-table>
  </div>
</template>

<script>
import banksTable from "./tables/BanksTable";
import directDebitTable from "./tables/DirectDebitTable";
export default {
  name: "BankTransfers",
  components: {
    banksTable,
    directDebitTable,
  },
};
</script>
