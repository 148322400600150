<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="70vw"
    @click:outside="closeForm"
  >
    <v-card v-if="editedItem">
      <v-form
        id="uploadBankCsv"
        ref="form"
        v-model="valid"
        @submit.prevent="saveItem"
        lazy-validation
      >
        <v-card-title>
          <h3 class="title-transaction">
            {{ formTitle }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-file-input
            truncate-length="50"
            show-size
            name="csv"
            accept=".csv"
            required
            v-model="form.csv"
            :rules="csvRules"
            :label="uploadLabel"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="gray" :disabled="sending" @click="closeForm">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            :disabled="sending"
            color="primary"
            @click.prevent="importTransactions"
          >
            {{ $t("Import") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        savedMessage
      }}</v-alert>
      <v-alert v-if="errorMessage" tile type="error" class="mb-0"
        >{{ errorMessage }}
        <div
          v-if="errorLog.info"
          style="max-height: 50vh; overflow-y: auto; margin-top: 5px"
        >
          <p v-for="(v, i) in errorLog.info" :key="'tr' + i">
            {{ i }}: {{ v }}
          </p>
        </div>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    meta: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      loading: false,
      savedMessage: null,
      errorMessage: null,
      errorLog: {},
      savedItem: false,
      csvRules: [
        (v) => !!v || "CSV file is required",
        (v) => {
          return (
            (v && v.name.toUpperCase().endsWith(".CSV")) ||
            "Please select a CSV file"
          );
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return (
        this.$t("Import transfers") +
        (this.editedItem ? " - " + this.editedItem.account_name : "")
      );
    },
    isSparkasse() {
      return this.editedItem && this.editedItem.type == "sparkasse";
    },
    uploadLabel() {
      return this.$t("banks.upload_csv");
    },
  },
  mounted() {},
  methods: {
    serializeForm(form) {
      var obj = {};
      var formData = new FormData(form);
      for (var key of formData.keys()) {
        obj[key] = formData.get(key);
      }
      return obj;
    },

    importTransactions() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.savedItem = false;
      this.sending = true;
      this.errorMessage = null;
      this.savedMessage = null;
      const form = document.getElementById("uploadBankCsv");
      this.upload("/banks/import/" + this.editedItem.id, new FormData(form))
        .then((r) => {
          this.loading = false;
          this.sending = false;
          if (r.success) {
            this.savedItem = true;
            this.savedMessage = r.message;
          } else {
            this.errorMessage = r.message;
            this.errorLog = r.log;
          }
          this.$emit("imported", this.form.csv);
        })
        .catch((r) => {
          this.errorMessage = r.message;
        });
    },
  },
};
</script>
